import { Add } from '@mui/icons-material';
import { Box, Button, Checkbox, Typography } from '@mui/joy';

import { Field } from '@features/Schedule/DesktopSchedule/EditEventModal/Field';
import { InstrumentsModal } from '@features/Settings2/ServiceSettings/InstrumentsModal';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

export const EditContactInstruments = () => {
  const {
    instruments: {
      availableInstruments,
      selectedInstruments,
      setSelectedInstruments,
      showInstrumentsModal,
      setShowInstrumentsModal,
    },
  } = useContactsProvider();

  return (
    <>
      <Field>
        <Typography level="title-md">Instruments</Typography>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '15px',
            alignItems: 'flex-start',
          }}
        >
          <Button
            size="sm"
            variant="verticalOutline"
            startDecorator={<Add />}
            onClick={() => setShowInstrumentsModal(true)}
            data-testid="add-instrument-button"
          >
            Add
          </Button>

          {availableInstruments.map((instrument) => {
            const checked = selectedInstruments.includes(instrument);

            return (
              <Button
                key={instrument}
                size="sm"
                variant="instrument"
                onClick={() =>
                  setSelectedInstruments((current) =>
                    current.includes(instrument)
                      ? current.filter((item) => item !== instrument)
                      : [...current, instrument]
                  )
                }
                startDecorator={
                  <>
                    <Checkbox overlay checked={checked} />
                    <img
                      alt="instrument icon"
                      src={`assets/instruments/${instrument.toLowerCase()}.svg`}
                    />
                  </>
                }
              >
                {instrument}
              </Button>
            );
          })}
        </Box>
      </Field>

      <InstrumentsModal
        isOpen={showInstrumentsModal}
        onClose={() => setShowInstrumentsModal(false)}
        selectedInstruments={selectedInstruments}
        setSelectedInstruments={setSelectedInstruments}
      />
    </>
  );
};
