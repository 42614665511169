import { UnfoldMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Input,
  Typography,
  autocompleteClasses,
  inputClasses,
  selectClasses,
} from '@mui/joy';
import { Timestamp } from 'firebase/firestore';

import { FullScreenModal } from '@components/FullScreenModal';
import { DateTimeInput } from '@features/Schedule/DesktopSchedule/EditEventModal/DateTimeInput';
import { Field } from '@features/Schedule/DesktopSchedule/EditEventModal/Field';
import { FieldGroup } from '@features/Schedule/DesktopSchedule/EditEventModal/FieldGroup';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';
import { EditAddress } from './EditAddress';
import { EditContactAvatar } from './EditContactAvatar';
import { EditContactInstruments } from './EditContactInstruments';

const inputHeight = '39px';

export const EditContactModal = () => {
  const {
    editContactModalOpen,
    setEditContactModalOpen,
    selectedContactId,
    inMemoryContact,
    setInMemoryContact,
    onSubmit,
    isValid,
  } = useContactsProvider();

  return (
    <FullScreenModal
      open={editContactModalOpen}
      onClose={() => setEditContactModalOpen(false)}
      title={selectedContactId ? 'Update Contact' : 'Create Contact'}
      testId="edit-contact-modal"
      right={
        <>
          <Button disabled={!isValid} onClick={onSubmit}>
            Save
          </Button>
        </>
      }
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            flex: 1,
            maxWidth: '650px',
            margin: 'auto',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            paddingBottom: '500px',

            [`& .${inputClasses.root}`]: {
              minHeight: inputHeight,
            },
            [`& .${inputClasses.root}, & .${autocompleteClasses.root}`]: {
              minHeight: inputHeight,
            },
            [`& .${selectClasses.root}`]: {
              backgroundColor: 'white',
              minHeight: inputHeight,
            },
          }}
        >
          {inMemoryContact ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <EditContactAvatar />

              <FieldGroup>
                <Field>
                  <Typography level="title-md">First</Typography>
                  <Input
                    placeholder="First name"
                    value={inMemoryContact.firstName}
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        firstName: event.target.value,
                      })
                    }
                  />
                </Field>

                <Field>
                  <Typography level="title-md">Last</Typography>
                  <Input
                    placeholder="Last name"
                    value={inMemoryContact.lastName}
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        lastName: event.target.value,
                      })
                    }
                  />
                </Field>
              </FieldGroup>

              <Field>
                <Typography level="title-md">Birthday</Typography>

                <DateTimeInput
                  hideTime
                  value={inMemoryContact.birthday?.toDate()}
                  onChange={(next) =>
                    setInMemoryContact({
                      ...inMemoryContact,
                      birthday: next ? Timestamp.fromDate(next) : null,
                    })
                  }
                />
              </Field>

              <FieldGroup>
                <Field>
                  <Typography level="title-md">Email</Typography>
                  <Input
                    placeholder="Email"
                    value={
                      inMemoryContact.emails?.find((email) => email.isPrimary)
                        ?.address
                    }
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        emails: (inMemoryContact.emails || []).map((email) =>
                          email.isPrimary
                            ? { ...email, address: event.target.value }
                            : email
                        ),
                      })
                    }
                  />
                </Field>

                <Field>
                  <Typography level="title-md">Phone</Typography>
                  <Input
                    placeholder="Phone"
                    value={
                      inMemoryContact.phones?.find((phone) => phone.isPrimary)
                        ?.number
                    }
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        phones: (inMemoryContact.phones || []).map((phone) =>
                          phone.isPrimary
                            ? { ...phone, number: event.target.value }
                            : phone
                        ),
                      })
                    }
                  />
                </Field>
              </FieldGroup>

              <EditContactInstruments />

              <Box>
                <Typography
                  level="h3"
                  sx={{ marginTop: '10px', marginBottom: '5px' }}
                >
                  Primary Contact
                </Typography>

                <Typography color="gray">
                  The primary contact will recieve all account notifications and
                  is responsible for payment.
                </Typography>
              </Box>

              <Field>
                <Typography level="title-md">Primary Contact</Typography>

                <Input
                  placeholder="This Contact"
                  endDecorator={<UnfoldMore sx={{ fontSize: '20px' }} />}
                />
              </Field>

              <FieldGroup>
                <Field>
                  <Typography level="title-md">Email</Typography>
                  <Input
                    placeholder="Email"
                    value={
                      inMemoryContact.emails?.find((email) => email.isPrimary)
                        ?.address
                    }
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        emails: (inMemoryContact.emails || []).map((email) =>
                          email.isPrimary
                            ? { ...email, address: event.target.value }
                            : email
                        ),
                      })
                    }
                  />
                </Field>

                <Field>
                  <Typography level="title-md">Phone</Typography>
                  <Input
                    placeholder="Phone"
                    value={
                      inMemoryContact.phones?.find((phone) => phone.isPrimary)
                        ?.number
                    }
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        phones: (inMemoryContact.phones || []).map((phone) =>
                          phone.isPrimary
                            ? { ...phone, number: event.target.value }
                            : phone
                        ),
                      })
                    }
                  />
                </Field>
              </FieldGroup>

              <EditAddress />
            </Box>
          ) : null}
        </Box>
      </Box>
    </FullScreenModal>
  );
};
