import { Input, Typography } from '@mui/joy';
import { Address } from '@shared/models/contacts';
import { useCallback, useMemo } from 'react';

import { Field } from '@features/Schedule/DesktopSchedule/EditEventModal/Field';
import { FieldGroup } from '@features/Schedule/DesktopSchedule/EditEventModal/FieldGroup';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

export const EditAddress = () => {
  const { inMemoryContact, setInMemoryContact } = useContactsProvider();

  const inMemoryAddress = useMemo(
    () =>
      inMemoryContact?.addresses?.find((address) => address.type === 'home'),
    [inMemoryContact]
  );

  const setAddress = useCallback(
    (key: keyof Address, next?: string | null) => {
      if (!inMemoryContact) {
        throw new Error('In memory contact is missing');
      }

      if (!next) {
        return;
      }

      setInMemoryContact({
        ...inMemoryContact,
        addresses: (inMemoryContact.addresses || []).map((address) =>
          address.type === 'home' ? { ...address, [key]: next } : address
        ),
      });
    },
    [inMemoryContact, setInMemoryContact]
  );

  return (
    <>
      <Typography level="h3" sx={{ marginTop: '10px' }}>
        Address
      </Typography>

      <FieldGroup>
        <Field>
          <Typography level="title-md">Street</Typography>
          <Input
            placeholder="Street"
            value={inMemoryAddress?.street1}
            onChange={(event) => setAddress('street1', event.target.value)}
          />
        </Field>

        <Field>
          <Typography level="title-md">Apt./Suite</Typography>
          <Input
            placeholder="Apt./Suite"
            value={inMemoryAddress?.street2}
            onChange={(event) => setAddress('street2', event.target.value)}
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field>
          <Typography level="title-md">City</Typography>
          <Input
            placeholder="City"
            value={inMemoryAddress?.city}
            onChange={(event) => setAddress('city', event.target.value)}
          />
        </Field>

        <Field>
          <Typography level="title-md">State</Typography>
          <Input
            placeholder="State"
            value={inMemoryAddress?.state}
            onChange={(event) => setAddress('state', event.target.value)}
          />
        </Field>
      </FieldGroup>

      <Field>
        <Typography level="title-md">Country</Typography>
        <Input
          placeholder="Country"
          value={inMemoryAddress?.country}
          onChange={(event) => setAddress('country', event.target.value)}
        />
      </Field>
    </>
  );
};
