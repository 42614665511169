import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Typography } from '@mui/joy';
import { IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import {
  TODAY,
  dateFormat,
  useCalendar,
} from '@features/Schedule/Calendar/useCalendar';

export interface CalendarPickerProps {
  value?: Date;
  onChange?: (date: Date) => void;
}

export const CalendarPicker = ({
  value = new Date(),
  onChange,
}: CalendarPickerProps) => {
  const [offset, setOffset] = useState(0);
  const calendar = useCalendar({
    inputDate: new Date(),
    expanded: false,
    expanding: false,
    offset,
  });

  const currentDateString = dayjs(value).format(dateFormat);
  const offsetDate = dayjs(TODAY).add(offset, 'month');
  const calendarDotsEnabled = useFeatureFlag('calendarDotsEnabled');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '15px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton onClick={() => setOffset(offset - 1)}>
          <ArrowBackIos sx={{ fontSize: '16px' }} />
        </IconButton>
        <Typography level="h2">
          {dayjs(offsetDate).format('MMM YYYY')}
        </Typography>
        <IconButton onClick={() => setOffset(offset + 1)}>
          <ArrowForwardIos sx={{ fontSize: '16px' }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          justifyItems: 'center',
          padding: 1,
          paddingTop: 0,
          marginTop: '-6px',
        }}
      >
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              color: 'gray',
              marginBottom: '3px',
            }}
          >
            {day}
          </Box>
        ))}
        {calendar.map((day, index) => (
          <Box
            key={index}
            onClick={() => onChange?.(new Date(`${day.date}T00:00:00`))}
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '5px 0',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              cursor: 'pointer',
              ...(day.date === TODAY ? { fontWeight: 800 } : undefined),
              ...(day.date === currentDateString
                ? { color: 'white', background: 'black' }
                : { background: 'transparent' }),
              ...(day.isCurrentMonth ? { opacity: 1 } : { opacity: 0 }),
              ...((day.hasEvent || Math.random() > 0.8) && calendarDotsEnabled
                ? {
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -6,
                      left: 0,
                      right: 0,
                      margin: 'auto',
                      height: '5px',
                      width: '5px',
                      background: 'darkgray',
                      borderRadius: '50%',
                    },
                  }
                : undefined),
            }}
          >
            {day.dayNumber}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
