import Box, { BoxProps } from '@mui/joy/Box';

export const FieldGroup = (props: BoxProps) => (
  <Box
    sx={{
      display: 'flex',
      gap: '20px',
      '& > div': {
        flex: 1,
      },
    }}
    {...props}
  />
);
