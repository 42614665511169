import { useIonRouter } from '@ionic/react';
import { Button, List, ListItem, ListItemButton } from '@mui/joy';
import { Popover } from '@mui/material';

import { useUserData } from '@components/UserData/useUserData';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import { signOut } from '@utils/firebase';

import { useFilterPopover } from '../useFilterPopover';

export const ProfileButton = () => {
  const {
    ref: buttonRef,
    filterIsOpen,
    setFilterIsOpen,
    anchorPosition,
  } = useFilterPopover();
  const router = useIonRouter();
  const organizationSlug = useOrganizationSlug();
  const { setSettingsIsOpen } = useUserData();

  return (
    <>
      <Button
        variant="plain"
        color="neutral"
        ref={buttonRef}
        onClick={() => setFilterIsOpen(true)}
        sx={{ paddingLeft: '10px', paddingRight: '16px', marginLeft: '0px' }}
      >
        Adrian
      </Button>
      <Popover
        open={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
      >
        <List size="sm">
          {[
            {
              title: 'Preferences',
              onClick: () => {
                setFilterIsOpen(false);
                router.push(
                  `/${organizationSlug}/settings/preferences`,
                  'none'
                );
              },
            },
            {
              title: 'Workspace settings',
              onClick: () => {
                setFilterIsOpen(false);
                router.push(`/${organizationSlug}/settings/general`, 'none');
              },
            },
            { title: 'Invite and manage members' },
            { title: 'Download App' },
            {
              title: 'Switch Organization',
              onClick: () => {
                setFilterIsOpen(false);
                setSettingsIsOpen(true);
              },
            },
            { title: 'Sign Out', onClick: signOut },
          ].map(({ title, onClick }) => (
            <ListItem key={title}>
              <ListItemButton
                variant="plain"
                color="neutral"
                {...(onClick && { onClick })}
              >
                {title}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};
