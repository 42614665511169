import { Autocomplete, AutocompleteOption, Box, Typography } from '@mui/joy';
import { ClickAwayListener, Paper, Popper } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFilterPopover } from '@features/Contacts/useFilterPopover';

import { CalendarPicker } from './CalendarPicker';

const formatDate = (inputDate: Date) => {
  const month = (inputDate.getMonth() + 1).toString();
  const day = inputDate.getDate().toString();
  const year = inputDate.getFullYear().toString();

  return `${month}/${day}/${year}`;
};

const formatTime = (inputDate: Date) => {
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';

  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const generateTimeLabelsWithDates = (input: Date) => {
  const year = input.getFullYear();
  const month = input.getMonth();
  const day = input.getDate();

  return Array.from({ length: 24 }).flatMap((_, hour) =>
    [0, 30].map((minute, minuteIndex) => {
      const date = new Date(year, month, day, hour, minute);

      return { label: formatTime(date), date, index: hour * 2 + minuteIndex };
    })
  );
};

interface DateTimeInputProps {
  value?: Date;
  onChange?: (value: Date | undefined) => void;
  hideTime?: boolean;
  children?: React.ReactNode;
  placeholder?: string;
}

export const DateTimeInput = ({
  value,
  onChange,
  hideTime,
  children,
  placeholder,
}: DateTimeInputProps) => {
  const { ref, filterIsOpen, setFilterIsOpen } =
    useFilterPopover<HTMLInputElement>();
  const [internalTextDate, setInternalTextDate] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const isTextDateValid = useMemo(
    () => !isNaN(new Date(internalTextDate).getTime()),
    [internalTextDate]
  );

  useEffect(() => {
    if (!value || isFocused || (internalTextDate && !isTextDateValid)) {
      return;
    }

    setInternalTextDate(formatDate(value));
  }, [value, isTextDateValid, filterIsOpen, internalTextDate, isFocused]);

  const currentDate = useMemo(() => value ?? new Date(), [value]);

  const setDate = useCallback(
    (input: Date) => {
      const date = new Date(input);

      if (value) {
        date.setHours(value.getHours());
        date.setMinutes(value.getMinutes());
      }

      onChange?.(date);
    },
    [value, onChange]
  );

  const time = useMemo(() => {
    const options = generateTimeLabelsWithDates(currentDate);
    const timeString = value ? formatTime(value) : null;

    const match = options.find((option) => option.label === timeString);

    return { options, value: match };
  }, [value, currentDate]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        border: '1px solid rgb(196, 196, 196)',
        borderRadius: '6px',
        padding: '0 10px',
        transition: 'border-color 0.3s',
        position: 'relative',
        '&:hover': {
          borderColor: 'rgb(0, 0, 0)',
        },
      }}
    >
      <Box
        placeholder={placeholder ?? 'month/day/year'}
        component="input"
        sx={{
          border: 'none',
          ...(!hideTime && { width: '86px' }),
          outline: 'none',
          minHeight: '37px',
          fontSize: '14px',
        }}
        data-is-date-input
        ref={ref}
        onFocus={() => {
          setIsFocused(true);
          setFilterIsOpen(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          if (!isTextDateValid && value) {
            setInternalTextDate(formatDate(value));
          }
        }}
        value={internalTextDate}
        onChange={(event) => {
          const next = event.target.value;

          if (!next) {
            onChange?.(undefined);
          }

          setInternalTextDate(next);

          const date = new Date(next);

          if (isNaN(date.getTime())) {
            return;
          }

          setDate(date);
        }}
      />
      <Popper
        open={filterIsOpen}
        anchorEl={ref.current}
        popperOptions={{
          placement: 'bottom',
          modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
        }}
      >
        <ClickAwayListener
          onClickAway={(event) => {
            const element =
              event.target instanceof HTMLElement ? event.target : null;

            if (!element?.closest(`[data-is-date-input]`)) {
              setFilterIsOpen(false);
            }
          }}
        >
          <Paper elevation={4}>
            <Box sx={{ padding: '20px' }}>
              <CalendarPicker
                value={currentDate}
                onChange={(start) => {
                  setInternalTextDate(formatDate(start));
                  setDate(start);
                  setFilterIsOpen(false);
                }}
              />
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      {hideTime ? null : (
        <>
          <Typography sx={{ color: 'gray' }}>at</Typography>

          <Autocomplete
            autoHighlight
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            onOpen={() =>
              setTimeout(() =>
                document
                  .querySelector(`[data-option-index="${time.value?.index}"]`)
                  ?.scrollIntoView()
              )
            }
            // Only needed to fix bug in JoyUI
            renderOption={(props, option) => (
              <AutocompleteOption
                key={(props as { key: string }).key}
                {...Object.fromEntries(
                  Object.entries(props).filter(([key]) => key !== 'key')
                )}
              >
                {option.label}
              </AutocompleteOption>
            )}
            options={time.options}
            value={time.value}
            onChange={(_, newValue) => {
              if (!newValue) {
                return;
              }

              onChange?.(newValue.date);
            }}
            sx={{
              border: 'none',
              minHeight: '37px',
              '& button': {
                display: 'none',
              },
              '& input': {
                width: '90px',
              },
            }}
          />
        </>
      )}

      {children}
    </Box>
  );
};
